<template>
  <v-autocomplete
    :label="$t('t.CountryIso')"
    :items="availableCountryIso2"
    :menu-props="{auto: true}"
    :clearable="true"
    :rules="rules"
    v-model="computedCountryIso2"
  />
</template>

<script>
export default {
  data () {
    return {
      availableCountryIso2: [],
      dataCountryIso2: null,
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument)
    }
  },
  async created () {
    const r = await this.$http().get('/core/v6/settings/country-codes-iso')

    this.availableCountryIso2 = r.data
  },
  computed: {
    computedCountryIso2: {
      get () {
        return this.dataCountryIso2
      },
      set (v) {
        this.dataCountryIso2 = v
        this.dataDocumentEmit()
      }
    }
  },
  methods: {
    emitDocument () {
      if (!this.lodash.isEqual(this.value, this.dataCountryIso2)) {
        this.$emit('input', this.dataCountryIso2)
      }
    }
  },
  props: {
    value: String,
    rules: Array
  },
  watch: {
    value: {
      immediate: true,
      handler (v) {
        this.dataCountryIso2 = v
      }
    }
  }
}
</script>

<style lang="stylus" scoped></style>
